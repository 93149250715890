<template>
  <el-form
      ref="loginRuleFormPwdRef"
      class="login-form"
      :model="loginForm"
      :rules="loginRules"
      hide-required-asterisk
  >
    <el-form-item prop="name">
      <el-input v-elInput class="login-input" :placeholder="formText.name"
                onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
                maxlength="20" @keydown.enter="loginWy"
                v-model="loginForm.name"/>
    </el-form-item>
    <el-form-item prop="code" class="login-code-single mt-56">
      <el-input v-elInput class="login-input" :type="pwdTypeStr" maxlength="30" :placeholder="formText.code"
                onkeyup="value=value.replace(/[\u4E00-\u9FA5]/g, '');"
                @keydown.enter="loginWy" v-model="loginForm.code">
        <template #suffix>
          <el-icon class="cur_p login-code-icon" @click.stop='changePwdType'>
            <component :is='pwdIcon'/>
          </el-icon>
        </template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {computed, getCurrentInstance, onMounted, reactive, ref, shallowRef, watch} from 'vue'
import {Hide, View} from "@element-plus/icons-vue";

const {proxy} = getCurrentInstance()
const loginRuleFormPwdRef = ref(null)
const props = defineProps({
  formData: {
    type: Object,
    default: () => {
      return {
        name: "",
        code: "",
      }
    }
  }
})
const emit = defineEmits(['update:formData', 'submit'])
const loginForm = reactive({
  name: "",
  code: "",
})
const pwdType = ref(false)
const pwdIcon = shallowRef(View)
const pwdTypeStr = computed(() => {
  return pwdType.value ? 'text' : 'password'
}, {
  onTrigger({newValue}) {
    pwdIcon.value = newValue ? View : Hide
  }
})
const formText = {
  name: "请输入账号",
  code: "请输入密码"
}
const changePwdType = () => {
  pwdType.value = !pwdType.value
}
// 登录验证规则
const loginRules = {
  name: [
    {required: true, message: formText.name, trigger: 'change'}
  ],
  code: [
    {required: true, message: formText.code, trigger: 'change'}
  ],
}
const loginWy = async () => {
  if (!loginRuleFormPwdRef.value) return
  await loginRuleFormPwdRef.value.validate((valid, fields) => {
    if (valid) {
      emit('submit')
    } else {
    }
  })
}

function setRememberPwd() {
  let rememberPwd = proxy.$cookies.get('rememberPwd')
  if (rememberPwd) {
    loginForm.name = rememberPwd.name
    loginForm.code = rememberPwd.code
  }
}

//设置监听loginForm的变化
watch(loginForm, (val) => {
  emit('update:formData', val)
})

onMounted(() => {
  setRememberPwd()
})


defineExpose({
  loginWy
})
</script>

<style lang="scss" src="./index.scss" scoped>
</style>