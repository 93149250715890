<template>
  <el-form
      ref="loginRuleFormPwdRef"
      class="login-form"
      :model="loginForm"
      :rules="loginRules"
      hide-required-asterisk
  >
    <el-form-item prop="name">
      <el-input v-elInput class="login-input" maxlength="11" v-number @blur="loginForm.name = $event.target.value"
                :placeholder="formText.name"
                @keydown.enter="loginWy" v-model="loginForm.name"/>
    </el-form-item>
    <el-form-item prop="code" class="login-code-single mt-56">
      <el-input v-elInput class="login-input" v-number type="text" :placeholder="formText.code" maxlength="6"
                @blur="loginForm.code = $event.target.value"
                @keydown.enter="loginWy" v-model="loginForm.code">
        <template #suffix>
          <span class="login-code-btn" :class="!isOK ? 'disabled':''" @click.stop="sendCode">{{ str }}</span>
        </template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {getCurrentInstance, reactive, ref, watch} from 'vue'
import {codeBtn} from "@utils/tool/getCode";
import {codeSend} from '@utils/api/person/index.js';
import {checkPhone} from "@utils/tool/checkPhone";

const {proxy} = getCurrentInstance()
const {str, getCode, isOK} = codeBtn()

const loginRuleFormPwdRef = ref(null)
const props = defineProps({
  formData: {
    type: Object,
    default: () => {
      return {
        name: "",
        code: "",
      }
    }
  }
})
const formText = {
  name: "请输入手机号",
  code: "请输入验证码"
}
const emit = defineEmits(['update:formData', 'submit'])
const loginForm = reactive({
  name: "",
  code: "",
})
// 登录验证规则
const loginRules = {
  name: [
    {required: true, message: formText.name, trigger: 'change'}
  ],
  code: [
    {required: true, message: formText.code, trigger: 'change'}
  ],
}
const loginWy = async () => {
  if (!loginRuleFormPwdRef.value) return
  await loginRuleFormPwdRef.value.validate((valid, fields) => {
    if (valid) {
      emit('submit')
    } else {
    }
  })
}

//设置监听loginForm的变化
watch(loginForm, (val) => {
  emit('update:formData', val)
})

//发送验证码
const sendCode = async () => {
  if (!isOK.value) return
  await loginRuleFormPwdRef.value.validateField('name', (valid) => {
    if (valid) {
      sendCodeApi()
    }
  })
}

let placeId = 4
//发送验证码
const sendCodeApi = async () => {
  if (!checkPhone(loginForm.name)) return
  if (!isOK.value) {
    return
  }
  const params = {
    phone: loginForm.name,
    placeId,
  }
  codeSend(params).then(res => {
    if (res.code === 0) {
      getCode()
    } else {
      proxy.$message.error(res.msg)
    }
  })

}


defineExpose({
  loginWy
})
</script>

<style lang="scss" src="./index.scss" scoped>
</style>