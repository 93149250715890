<template>
  <div class="zh-login">
    <div class="login-box">
      <div class="login-content">
        <div class="login-title">登录</div>
        <div class="login-tab">
          <div class="login-single cur_p" :class="tabChecked === 1 ?'active':''" @click="tabCheck(1)">密码登录</div>
          <div class="login-single cur_p" :class="tabChecked === 0 ?'active':''" @click="tabCheck(0)">验证码登录</div>
        </div>
        <LoginWithPwd ref="loginWithPwdRef" v-show="tabChecked === 1" v-model:form-data="loginForm" @submit="loginWy"/>
        <LoginWithPhone ref="loginWithPhoneRef" v-show="tabChecked === 0" v-model:form-data="loginForm"
                        @submit="loginWy"></LoginWithPhone>
        <div class="login-control mt-56" :style="{opacity:tabChecked === 1 ?1:0}">
          <el-checkbox v-model="checked">保持登录</el-checkbox>
          <div class="c_blue cur_p"></div>
        </div>
        <div class="login-btn-box">
          <el-button type="primary" class="login-btn" @click="loginWyNew">登录</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import {getCurrentInstance, onMounted, ref} from 'vue'
import {encrypt} from '@utils/tool'
import {loginIn, loginInCode} from '@utils/api/user'
import {useRouter ,useRoute} from "vue-router";
import {useStore} from "vuex";
import LoginWithPwd from "@views/login/component/loginWithPwd.vue";
import LoginWithPhone from "@views/login/component/loginWithPhone.vue";

const store = useStore()
const route = useRoute()
const loginWithPwdRef = ref(null)
const loginWithPhoneRef = ref(null)
const router = useRouter()
const {proxy} = getCurrentInstance()
const checked = ref(false)
const tabChecked = ref(1) //0 手机号登录 1 账号密码登录
const loginForm = ref({
  name: '',
  code: '',
})
const tabCheck = function (e) {
  tabChecked.value = e
}

async function loginWy() {
  if (proxy.$cookies.get('userInfo')?.username) {
    return
  }
  if (!loginForm.value.name && !loginForm.value.code) return
  let loginInfo = {}
  if (tabChecked.value === 1) {
    loginInfo = await loginIn({
      account: loginForm.value.name,
      password: encrypt(loginForm.value.code)
    })
  } else {
    loginInfo = await loginInCode({
      mobile: loginForm.value.name,
      vcode: loginForm.value.code
    })
  }
  if (loginInfo.code !== 0) {
    proxy.$message.error(loginInfo.msg)
    return
  }
  proxy.$cookies.set('userInfo', loginInfo.data, -1)
  isRememberPwd(loginInfo.data.token)
  proxy.$message.success('登录成功,正在跳转')
  setTimeout(() => {
    proxy.$message.close()
    router.push('/home')
  }, 100)
}

const loginWyNew = async () => {
  if (tabChecked.value === 1) {
    await loginWithPwdRef.value.loginWy()
  } else {
    await loginWithPhoneRef.value.loginWy()
  }
}

//判断是否记住密码
function isRememberPwd(data) {
  if (checked.value && tabChecked.value === 1) {
    localStorage.setItem('wyLoginToken_WYLG', data)
  } else {
    sessionStorage.setItem('wyLoginToken_WYLG', data)
  }
}

onMounted(()=>{
  const code = route.query?.code || null
  console.log(code)
})


</script>

<script>
export default {
  name: "loginForm"
}
</script>

<style lang="scss" src="./index.scss" scoped>
</style>
